
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'

import { ChecklistTemplateOutput } from '@/api/types/checklistTemplate'
import { useDeleteChecklistTemplate, useGetChecklistTemplates } from '@/api/checklistTemplate'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'ChecklistTemplates',
  components: {
    AddEditChecklistTemplateDialog: () =>
      import('@/views/baseData/views/checklistTemplates/components/AddEditChecklistTemplateDialog.vue'),

    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const { exec: getChecklistTemplates, data: checklistTemplates } = useGetChecklistTemplates()

    const { addNotification } = useNotify()

    const isAddEditDialogOpen = ref(false)

    const isDeleteDialogOpen = ref(false)

    const activeChecklistTemplate = ref<ChecklistTemplateOutput | null>(null)

    const { deleteChecklistTemplate: deleteChecklistTemplateXhr } = useDeleteChecklistTemplate()

    async function onDeleteChecklistTemplate(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      try {
        if (activeChecklistTemplate.value?.id) {
          await deleteChecklistTemplateXhr(activeChecklistTemplate.value.id)
        }

        addNotification({
          text: root.$t(`baseData.checklistTemplates.delete.message`) as string,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      } finally {
        onCloseAddEditDialog()
      }

      init()
    }

    function onDelete(checklistTemplate: ChecklistTemplateOutput): void {
      activeChecklistTemplate.value = cloneDeep(checklistTemplate)

      isDeleteDialogOpen.value = true
    }

    function onEdit(checklistTemplate: ChecklistTemplateOutput): void {
      activeChecklistTemplate.value = cloneDeep(checklistTemplate)

      isAddEditDialogOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditDialogOpen.value = false
      isDeleteDialogOpen.value = false

      activeChecklistTemplate.value = null
    }

    async function init(): Promise<void> {
      await getChecklistTemplates({ params: { sort: 'id,asc' } })
      checklistTemplates.value.map((template) =>
        template.checklistTaskSet.sort((a, b) => (a.title > b.title ? 1 : -1))
      )
    }
    init()

    return reactive({
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
      },
      constants: {
        Rights,
      },
      state: {
        checklistTemplates,

        isAddEditDialogOpen,

        isDeleteDialogOpen,
        activeChecklistTemplate,
      },
      functions: {
        init,
        hasSufficientRights,
      },
      listeners: {
        onDelete,
        onDeleteChecklistTemplate,

        onEdit,

        onCloseAddEditDialog,
      },
    })
  },
})

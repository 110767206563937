var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-flex flex-column align-end my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_TEMPLATE_CREATE) ? _c('v-btn', {
    staticClass: "mb-3 rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditDialogOpen = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.actions.addChecklistTemplate.title')) + " ")], 1) : _vm._e(), _c('v-expansion-panels', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "accordion": "",
      "flat": ""
    }
  }, [_c('v-expansion-panel', {
    attrs: {
      "readonly": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "cursor-default",
    staticStyle: {
      "border-bottom": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "hide-actions": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.table.col.title.title')) + " ")]), _c('v-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.table.col.title.description')) + " ")]), _c('v-col')], 1)], 1)], 1), _vm._l(_vm.state.checklistTemplates, function (checklistTemplate) {
    return _c('v-expansion-panel', {
      key: checklistTemplate.id,
      staticStyle: {
        "border-bottom": "1px solid rgba(0, 0, 0, 0.12)"
      }
    }, [_c('v-expansion-panel-header', {
      attrs: {
        "hide-actions": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(checklistTemplate.title) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(checklistTemplate.description) + " ")]), _c('v-col', {
      staticClass: "text-right"
    }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_TEMPLATE_UPDATE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onEdit(checklistTemplate);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_TEMPLATE_DELETE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onDelete(checklistTemplate);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel-content', {
      staticClass: "grey lighten-3"
    }, [checklistTemplate.checklistTaskSet.length ? _c('div', [_c('v-row', {
      staticClass: "mt-2"
    }, [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.table.col.title.checklistTaskSet')) + " ")])]), checklistTemplate.checklistTaskSet.length ? _vm._l(checklistTemplate.checklistTaskSet, function (entry) {
      return _c('v-chip', {
        key: entry.id,
        staticClass: "mt-6 mx-2",
        attrs: {
          "small": ""
        }
      }, [_c('span', [_vm._v(" " + _vm._s(entry.title) + " ")])]);
    }) : _vm._e()], 2) : _c('div', [_c('v-row', {
      staticClass: "mt-2"
    }, [_c('span', {
      staticClass: "font-weight-bold text-subtitle-3"
    }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.table.col.title.emptyChecklistTaskSet')) + " ")])])], 1)])], 1);
  })], 2), _vm.state.isAddEditDialogOpen ? _c('AddEditChecklistTemplateDialog', {
    attrs: {
      "checklist-template-to-edit": _vm.state.activeChecklistTemplate
    },
    on: {
      "reload": function reload($event) {
        return _vm.functions.init();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditDialogOpen", $$v);
      },
      expression: "state.isAddEditDialogOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteDialogOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteChecklistTemplate();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.deleteChecklistTemplate.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }